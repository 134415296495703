import {combineReducers} from 'redux'
import reducer from './reducer'
import ModalReducer from './ModalReducer/ModalReducer'
import UserReducer from './User/UserReducer'
import DataFetchingReducer from './DataFetching/DataFetchingReducer'


const rootReducer = combineReducers({

    reducer:reducer,
    modal:ModalReducer,
    user:UserReducer,
    dataFetch:DataFetchingReducer,
    
})

export default rootReducer
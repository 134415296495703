import {
  OPEN_MODAL_ADDMEMBERS,
  CLOSE_MODAL_ADDMEMBERS,
  OPEN_MODAL_UPDATEMEMBERS,
  CLOSE_MODAL_UPDATEMEMBERS,

  OPEN_MODAL_ADDADMIN,
  CLOSE_MODAL_ADDADMIN,
  OPEN_MODAL_UPDATEADMIN,
  CLOSE_MODAL_UPDATEADMIN,

  OPEN_MODAL_ADDSCHEDULE,
  CLOSE_MODAL_ADDSCHEDULE,
  OPEN_MODAL_UPDATESCHEDULE,
  CLOSE_MODAL_UPDATESCHEDULE,

  OPEN_MODAL_ADDASSETS,
  CLOSE_MODAL_ADDASSETS,
  OPEN_MODAL_UPDATEASSETS,
  CLOSE_MODAL_UPDATEASSETS,
  
  OPEN_MODAL_ADDEVENTCALENDAR,
  CLOSE_MODAL_ADDEVENTCALENDAR,


  OPEN_MODAL_ADDFINTRAN,
  CLOSE_MODAL_ADDFINTRAN,


  OPEN_MODAL_REVIEWMEMBERS,
  CLOSE_MODAL_REVIEWMEMBERS,

  OPEN_MODAL_PRINTMEMBERS,
  CLOSE_MODAL_PRINTMEMBERS,

  OPEN_MODAL_REVIEWPENDINGMEMBERS,
  CLOSE_MODAL_REVIEWPENDINGMEMBERS,
  
  /* STAFF */
  OPEN_MODAL_UPDATESTAFFADMIN,
  CLOSE_MODAL_UPDATESTAFFADMIN, 
  OPEN_MODAL_UPDATESTAFFEMPLOYEE,
  CLOSE_MODAL_UPDATESTAFFEMPLOYEE, 
  OPEN_MODAL_UPDATESTAFFGIDN,
  CLOSE_MODAL_UPDATESTAFFGIDN, 
  OPEN_MODAL_UPDATESTAFFDEPARTMENT,
  CLOSE_MODAL_UPDATESTAFFDEPARTMENT, 


  OPEN_MODAL_UPDATETITO,
  CLOSE_MODAL_UPDATETITO,



  OPEN_MODAL_UPDATE_ACCTYPES,
  CLOSE_MODAL_UPDATE_ACCTYPES,
  OPEN_MODAL_UPDATE_ACCDES,
  CLOSE_MODAL_UPDATE_ACCDES,
} from "./ModalTypes";

const initialState = {
  isModalAddMembersOpen: false,
  isModalUpdateMembersOpen: false,




  isModalAddAdminOpen: false,
  isModalUpdateAdminOpen: false,




  isModalAddScheduleOpen: false,
  isModalUpdateScheduleOpen: false,




  isModalAddAssetsOpen: false,
  isModalUpdateAssetsOpen: false,




  isModalAddEventCalendarOpen:false,
  isModalUpdateEventCalendarOpen:false,



  isModalAddFinTranOpen:false,
  


  isModalReviewMembersOpen:false,
  isModalPrintMembersOpen:false,
  isModalReviewPendingMembersOpen:false,



  isModalUpdateStaffAdminOpen:false,
  isModalUpdateStaffEmployeeOpen:false,
  isModalUpdateStaffDepartmentOpen:false,
  isModalUpdateStaffGIDNOpen:false,


  isModalUpdateTITOOpen:false,


  isModalUpdateAccDesOpen:false,
  isModalUpdateAccTypesOpen:false,

};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_ADDMEMBERS:
      return {
        ...state,
        isModalAddMembersOpen: true,
      };
    case CLOSE_MODAL_ADDMEMBERS:
      return {
        ...state,
        isModalAddMembersOpen: false,
      };
    case OPEN_MODAL_UPDATEMEMBERS:
      return {
        ...state,
        isModalUpdateMembersOpen: true,
      };
    case CLOSE_MODAL_UPDATEMEMBERS:
      return {
        ...state,
        isModalUpdateMembersOpen: false,
      };




    case OPEN_MODAL_ADDADMIN:
      return {
        ...state,
        isModalAddAdminOpen: true,
      };
    case CLOSE_MODAL_ADDADMIN:
      return {
        ...state,
        isModalAddAdminOpen: false,
      };
    case OPEN_MODAL_UPDATEADMIN:
      return {
        ...state,
        isModalUpdateAdminOpen: true,
      };
    case CLOSE_MODAL_UPDATEADMIN:
      return {
        ...state,
        isModalUpdateAdminOpen: false,
      };




    case OPEN_MODAL_ADDSCHEDULE:
      return {
        ...state,
        isModalAddScheduleOpen: true,
      };
    case CLOSE_MODAL_ADDSCHEDULE:
      return {
        ...state,
        isModalAddScheduleOpen: false,
      };
    case OPEN_MODAL_UPDATESCHEDULE:
    return {
      ...state,
      isModalUpdateScheduleOpen: true,
    };
    case CLOSE_MODAL_UPDATESCHEDULE:
      return {
        ...state,
        isModalUpdateScheduleOpen: false,
      };



    case OPEN_MODAL_ADDASSETS:
      return {
        ...state,
        isModalAddAssetsOpen: true,
      };
    case CLOSE_MODAL_ADDASSETS:
      return {
        ...state,
        isModalAddAssetsOpen: false,
      };
    case OPEN_MODAL_UPDATEASSETS:
      return {
        ...state,
        isModalUpdateAssetsOpen: true,
      };
    case CLOSE_MODAL_UPDATEASSETS:
      return {
        ...state,
        isModalUpdateAssetsOpen: false,
      };



    case OPEN_MODAL_ADDEVENTCALENDAR:
      return {
        ...state,
        isModalAddEventCalendarOpen: true,
      };
    case CLOSE_MODAL_ADDEVENTCALENDAR:
      return {
        ...state,
        isModalAddEventCalendarOpen: false,
      };



    case OPEN_MODAL_ADDFINTRAN:
      return {
        ...state,
        isModalAddFinTranOpen: true,
      };
    case CLOSE_MODAL_ADDFINTRAN:
      return {
        ...state,
        isModalAddFinTranOpen: false,
      };

      

    case OPEN_MODAL_REVIEWMEMBERS:
      return {
        ...state,
        isModalReviewMembersOpen: true,
      };
    case CLOSE_MODAL_REVIEWMEMBERS:
      return {
        ...state,
        isModalReviewMembersOpen: false,
      };
      


    case OPEN_MODAL_PRINTMEMBERS:
    return {
      ...state,
      isModalPrintMembersOpen: true,
    };
    case CLOSE_MODAL_PRINTMEMBERS:
      return {
        ...state,
        isModalPrintMembersOpen: false,
      };


      
    case OPEN_MODAL_REVIEWPENDINGMEMBERS:
      return {
        ...state,
        isModalReviewPendingMembersOpen: true,
      };
    case CLOSE_MODAL_REVIEWPENDINGMEMBERS:
      return {
        ...state,
        isModalReviewPendingMembersOpen: false,
      };
    
    
    case OPEN_MODAL_UPDATESTAFFADMIN:
      return {
        ...state,
        isModalUpdateStaffAdminOpen: true,
      };
    case CLOSE_MODAL_UPDATESTAFFADMIN:
      return {
        ...state,
        isModalUpdateStaffAdminOpen: false,
      };
    case OPEN_MODAL_UPDATESTAFFEMPLOYEE:
      return {
        ...state,
        isModalUpdateStaffEmployeeOpen: true,
      };
    case CLOSE_MODAL_UPDATESTAFFEMPLOYEE:
      return {
        ...state,
        isModalUpdateStaffEmployeeOpen: false,
      };
    case OPEN_MODAL_UPDATESTAFFGIDN:
      return {
        ...state,
        isModalUpdateStaffGIDNOpen: true,
      };
    case CLOSE_MODAL_UPDATESTAFFGIDN:
      return {
        ...state,
        isModalUpdateStaffGIDNOpen: false,
      };
    case OPEN_MODAL_UPDATESTAFFDEPARTMENT:
      return {
        ...state,
        isModalUpdateStaffDepartmentOpen: true,
      };
    case CLOSE_MODAL_UPDATESTAFFDEPARTMENT:
      return {
        ...state,
        isModalUpdateStaffDepartmentOpen: false,
      };
    


    case OPEN_MODAL_UPDATETITO:
      return {
        ...state,
        isModalUpdateTITOOpen: true,
      };
    case CLOSE_MODAL_UPDATETITO:
      return {
        ...state,
        isModalUpdateTITOOpen: false,
      };    
    


    case OPEN_MODAL_UPDATE_ACCTYPES:
      return {
        ...state,
        isModalUpdateAccTypesOpen: true,
      };
    case CLOSE_MODAL_UPDATE_ACCTYPES:
      return {
        ...state,
        isModalUpdateAccTypesOpen: false,
      };  
      case OPEN_MODAL_UPDATE_ACCDES:
    return {
      ...state,
      isModalUpdateAccDesOpen: true,
    };
    case CLOSE_MODAL_UPDATE_ACCDES:
      return {
        ...state,
        isModalUpdateAccDesOpen: false,
      };        
    default:
      return state;
  }
};

export default ModalReducer;

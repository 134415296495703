export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const ERROR_FETCH_MEMBERS = "ERROR_FETCH_MEMBERS";
export const CLEAR_MEMBERS = "CLEAR_MEMBERS";

export const FETCH_ACCOUNT_TYPES = "FETCH_ACCOUNT_TYPES";
export const ERROR_FETCH_ACCOUNTS = "ERROR_FETCH_ACCOUNTS";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";

export const FETCH_ACCOUNT_DESCRIPTIONS = "FETCH_ACCOUNT_DESCRIPTIONS";
export const ERROR_FETCH_DESCRIPTIONS = "ERROR_FETCH_DESCRIPTIONS";
export const CLEAR_DESCRIPTIONS = "CLEAR_DESCRIPTIONS";





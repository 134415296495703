export const OPEN_MODAL_ADDMEMBERS = "OPEN_MODAL_ADDMEMBERS";
export const CLOSE_MODAL_ADDMEMBERS = "CLOSE_MODAL_ADDMEMBERS";
export const OPEN_MODAL_UPDATEMEMBERS = "OPEN_MODAL_UPDATEMEMBERS";
export const CLOSE_MODAL_UPDATEMEMBERS = "CLOSE_MODAL_UPDATEMEMBERS";

export const OPEN_MODAL_ADDADMIN = "OPEN_MODAL_ADDADMIN";
export const CLOSE_MODAL_ADDADMIN = "CLOSE_MODAL_ADDADMIN";
export const OPEN_MODAL_UPDATEADMIN = "OPEN_MODAL_UPDATEADMIN";
export const CLOSE_MODAL_UPDATEADMIN = "CLOSE_MODAL_UPDATEADMIN";

export const OPEN_MODAL_ADDSCHEDULE = "OPEN_MODAL_ADDSCHEDULE";
export const CLOSE_MODAL_ADDSCHEDULE = "CLOSE_MODAL_ADDSCHEDULE";
export const OPEN_MODAL_UPDATESCHEDULE = "OPEN_MODAL_UPDATESCHEDULE";
export const CLOSE_MODAL_UPDATESCHEDULE = "CLOSE_MODAL_UPDATESCHEDULE";

export const OPEN_MODAL_ADDASSETS = "OPEN_MODAL_ADDASSETS";
export const CLOSE_MODAL_ADDASSETS = "CLOSE_MODAL_ADDASSETS";
export const OPEN_MODAL_UPDATEASSETS = "OPEN_MODAL_UPDATEASSETS";
export const CLOSE_MODAL_UPDATEASSETS = "CLOSE_MODAL_UPDATEASSETS";

export const OPEN_MODAL_ADDEVENTCALENDAR = "OPEN_MODAL_ADDEVENTCALENDAR";
export const CLOSE_MODAL_ADDEVENTCALENDAR = "CLOSE_MODAL_ADDEVENTCALENDAR";

export const OPEN_MODAL_ADDFINTRAN = "OPEN_MODAL_ADDFINTRAN";
export const CLOSE_MODAL_ADDFINTRAN = "CLOSE_MODAL_ADDFINTRAN";

export const OPEN_MODAL_REVIEWMEMBERS = "OPEN_MODAL_REVIEWMEMBERS";
export const CLOSE_MODAL_REVIEWMEMBERS = "CLOSE_MODAL_REVIEWMEMBERS";

export const OPEN_MODAL_PRINTMEMBERS = "OPEN_MODAL_PRINTMEMBERS";
export const CLOSE_MODAL_PRINTMEMBERS = "CLOSE_MODAL_PRINTMEMBERS";

export const OPEN_MODAL_REVIEWPENDINGMEMBERS = "OPEN_MODAL_REVIEWPENDINGMEMBERS";
export const CLOSE_MODAL_REVIEWPENDINGMEMBERS = "CLOSE_MODAL_REVIEWPENDINGMEMBERS";


//STAFF
export const OPEN_MODAL_UPDATESTAFFADMIN = "OPEN_MODAL_UPDATESTAFFADMIN";
export const CLOSE_MODAL_UPDATESTAFFADMIN = "CLOSE_MODAL_UPDATESTAFFADMIN";
export const OPEN_MODAL_UPDATESTAFFEMPLOYEE = "OPEN_MODAL_UPDATESTAFFEMPLOYEE";
export const CLOSE_MODAL_UPDATESTAFFEMPLOYEE = "CLOSE_MODAL_UPDATESTAFFEMPLOYEE";
export const OPEN_MODAL_UPDATESTAFFGIDN = "OPEN_MODAL_UPDATESTAFFGIDN";
export const CLOSE_MODAL_UPDATESTAFFGIDN = "CLOSE_MODAL_UPDATESTAFFGIDN";
export const OPEN_MODAL_UPDATESTAFFDEPARTMENT = "OPEN_MODAL_UPDATESTAFFDEPARTMENT";
export const CLOSE_MODAL_UPDATESTAFFDEPARTMENT = "CLOSE_MODAL_UPDATESTAFFDEPARTMENT";




//TITO
export const OPEN_MODAL_UPDATETITO = "OPEN_MODAL_UPDATETITO";
export const CLOSE_MODAL_UPDATETITO = "CLOSE_MODAL_UPDATETITO";

//FINANCIAL ACCOUNTS
export const OPEN_MODAL_UPDATE_ACCDES = "OPEN_MODAL_UPDATE_ACCDES";
export const CLOSE_MODAL_UPDATE_ACCDES = "CLOSE_MODAL_UPDATE_ACCDES";
export const OPEN_MODAL_UPDATE_ACCTYPES = "OPEN_MODAL_UPDATE_ACCTYPES";
export const CLOSE_MODAL_UPDATE_ACCTYPES = "CLOSE_MODAL_UPDATE_ACCTYPES";
import axios from "axios";
import React from "react";
import thunk from 'redux-thunk';
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter as Router } from "react-router-dom";
import { composeWithDevTools } from 'redux-devtools-extension';

import App from "App/index";
import config from "./config";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./store/rootReducer";


const middleWare=[thunk]

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleWare)));
axios.defaults.baseURL =  "https://api.onlinecoop.net/"
ReactDOM.render(
  <Provider store={store}>
    <Router>
      {/* basename="/datta-able" */}
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

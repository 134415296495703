import {

  FETCH_MEMBERS,
  ERROR_FETCH_MEMBERS,
  CLEAR_MEMBERS,

  FETCH_ACCOUNT_TYPES,
  ERROR_FETCH_ACCOUNTS,
  CLEAR_ACCOUNTS,

  FETCH_ACCOUNT_DESCRIPTIONS,
  ERROR_FETCH_DESCRIPTIONS,
  CLEAR_DESCRIPTIONS,

} from "./DataFetchingTypes";

const initialState = {
  members: [],
  errorMembers: "",
  accounts: [],
  errorAccounts: "",
  descriptions: [],
  errorDescriptions: [],
  loading: true,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type)
  {

    //MEMBERS
    case FETCH_MEMBERS:
      return {
        ...state,
        members: action.payload,
        errorMembers: false
      };
    case ERROR_FETCH_MEMBERS:
      return {
        ...state,
        members: [],
        errorMembers: action.payload,
      };
    case CLEAR_MEMBERS:
      return {
        ...state,
        members: [],
        errorMembers: "",
      };


    //ACCOUNT TYPES
    case FETCH_ACCOUNT_TYPES:
      return {
        ...state,
        accounts: action.payload,
        errorAccounts: false,
      };
    case ERROR_FETCH_ACCOUNTS:
      return {
        ...state,
        accounts: [],
        errorAccounts: action.payload,
      };
    case CLEAR_ACCOUNTS:
      return {
        ...state,
        accounts: [],
        errorAccounts: "",
      };


    //ACCOUNT DESCRIPTIONS
    case FETCH_ACCOUNT_DESCRIPTIONS:
      return {
        ...state,
        descriptions: action.payload,
        errorDescriptions: false,
      };
    case ERROR_FETCH_DESCRIPTIONS:
      return {
        ...state,
        descriptions: [],
        errorDescriptions: action.payload,
      };
    case CLEAR_DESCRIPTIONS:
      return {
        ...state,
        descriptions: [],
        errorDescriptions: "",
      };
    default:
      return state;
  }
};

export default UserReducer;

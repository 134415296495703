 import React from 'react';

 const SignIn1 = React.lazy(() => import('pages/authentication/SignIn/SignIn1'));
 const SignUp3 = React.lazy(() => import('pages/authentication/SignUp/SignUp3'));
 const SignUp4 = React.lazy(() => import('pages/authentication/SignUp/SignUp4'));
 const Signin3 = React.lazy(() => import('pages/authentication/SignIn3'));
 const ResetPassword3 = React.lazy(() => import('pages/authentication/ResetPassword/ResetPassword3'));
//  const VerifySession = React.lazy(() => import('./authentication/verify/'));
 const ComingSoon = React.lazy(() => import('pages/authentication/Maintenance/ComingSoon'));


 const route = [
    { path: '/auth/signin-1', exact: true, name: 'Signin 1', component: SignIn1 },
    { path: '/sign-in', exact: true, name: 'Signin 3', component: Signin3 },
    { path: '/sign-up', exact: true, name: 'Signin 3', component: SignUp3 },
    { path: '/sign-up2', exact: true, name: 'SignUp 2', component: SignUp4 },
    { path: '/reset-password', exact: true, name: 'Signin 3', component: ResetPassword3 },
   //  { path: '/verify', exact: true, name: 'Signin 3', component: VerifySession },
    { path: '/coming-soon', exact: true, name: 'Coming Soon', component: ComingSoon },
 ];

 export default route;
import {
  LOGIN_USER,
  LOGIN_ERROR,
  LOGOUT_USER,
  RESET_STATE,
  SET_TOKEN,
  INITIALIZED
} from "./UserTypes";

const initialState = {
  userCreds: [],
  loading: true,
  error: "",
  isUserLoggedin: false,
  token: "",
  initialized: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isUserLoggedin: true,
        token: window.localStorage.getItem('tokenOCCC'),
        loading: false,
        userCreds: action.payload,
        error: ""
      };


    case LOGOUT_USER:
      return {
        ...state,
        isUserLoggedin: false,
        response: "",
        userCreds: "",
        token: "",
        error: ""
      };

    case LOGIN_ERROR:
      return {
        ...state,
        isUserLoggedin: false,
        loading: false,
        error: action.payload,
      };


    case SET_TOKEN:
      return {
        ...state,
        // token:
      };


    case RESET_STATE:
      return {
        userCreds: [],
        loading: true,
        error: "",
        isUserLoggedin: false,
        token: "",
      };

    case INITIALIZED:
      return {
        ...state,
        initialized: true,
      };




    default:
      return state;
  }
};

export default UserReducer;
